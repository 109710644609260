import { Injectable, signal } from '@angular/core';
import { UserI } from '../../core/shared/interfaces/entities/user.interface';

@Injectable({
  providedIn: 'root',
})
export class BusinessAccountsService {
  constructor() {}

  selectedBusinessAccount = signal<UserI | null>(null);
}
